import React, {useContext, useEffect, useState} from "react";
import algoliaSearch from "algoliasearch/lite";
import {
    InstantSearch,
    Configure,
    SearchBox,
    connectAutoComplete,
    Highlight,
} from "react-instantsearch-dom";

import { BsArrowUpRight } from "@react-icons/all-files/bs/BsArrowUpRight";
import styles from "./styles.module.css";
import {usePrintout} from '@/context/PrintoutContext';
import {AppContext} from '@/context/AppContext';

const searchClient = algoliaSearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
);

const AutocompleteUI = ({
                            hits,
                            currentRefinement,
                            refine,
                            onSearch,
                            setSelectedHit,
                            setSearchState,
}) => {

//    if (currentRefinement.length < 3) return null;

    if (!hits.length || !currentRefinement) return null;


    const handleHitClick = (hit) => {
        setSelectedHit(hit);
        setSearchState(hit.name);
    };

    return (
        <div className={styles.autocomplete_container}>
            <ul className={styles.autocomplete_suggestions_menu} role="listbox">
                {hits.map((hit) => (
                    <li
                        key={hit.objectID}
                        role="option"
                        onClick={() => {
                            handleHitClick(hit);
                            refine(hit.name);
                            onSearch(hit.name);
                        }}
                    >
                        <Highlight hit={hit} attribute="name" />
                        <BsArrowUpRight />
                    </li>
                ))}
            </ul>
        </div>
    );
};

const ConnectedAutocomplete = connectAutoComplete(AutocompleteUI);

const Autocomplete = ({ onSearch = () => {}, hits, currentRefinement, refine }) => {
    const { setAlgoliaPrintouts, printouts } = usePrintout();
    const { setCurrentAlgoliaQuery } = useContext(AppContext);


    const [hideSuggestions, setHideSuggestions] = useState(false);
    const [searchState, setSearchState] = useState('');
    const [selectedHit, setSelectedHit] = useState(null);

    const handleSearchStateChange = (query) => {

        setSearchState(query);
        setHideSuggestions(query === '');
        setCurrentAlgoliaQuery(query);
        // Reset selectedHit if searchState changes
        if (query === '') {
//            setHideSuggestions(false);
            setSelectedHit(null);
        }
//        if (query.length >= 3) {
//            refine(query);
//        } else {
//            // Optionally, clear the hits if less than 3 characters
//            refine('');
//        }
    };

    useEffect(() => {
        if (!searchState) {
            setAlgoliaPrintouts([]);
        }
        else {
            setAlgoliaPrintouts(printouts.filter(printout =>
                printout.publication.name.toLowerCase().includes(searchState.toLowerCase())
            ));
        }
    }, [searchState]);

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME}
            searchState={{ query: searchState }}
            onSearchStateChange={({ query }) => handleSearchStateChange(query)}
        >
            <Configure hitsPerPage={searchState ? 5 : 0} />

            <SearchBox
                aria-label="Search"
                value={searchState}
                onChange={(e) => handleSearchStateChange(e.currentTarget.value)}
                onSubmit={(e) => e.preventDefault()}
                translations={{ placeholder: "" }}
            />
            {!hideSuggestions && (
                <ConnectedAutocomplete
                    onSearch={onSearch}
                    setSelectedHit={setSelectedHit}
                    setSearchState={setSearchState}
                />
            )}
        </InstantSearch>
    );
};

export default Autocomplete;
